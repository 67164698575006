<template>
  <div>
    <q-form ref="editForm">
      <c-card title="LBLBASEINFO" class="cardClassDetailForm">
        <template slot="card-button">
          <q-btn-group outline >
            <c-btn
              v-show="editable && isOld && !disabled"
              :url="completeUrl"
              :isSubmit="isComplete"
              :param="traningEdu"
              mappingType="PUT"
              label="평가실시완료"
              icon="check"
              @beforeAction="completeInfo"
              @btnCallback="completeCallback" />
            <c-btn
              v-show="editable && !disabled"
              :url="saveUrl"
              :isSubmit="isSave"
              :param="traningEdu"
              mappingType="PUT"
              label="LBLSAVE"
              icon="save"
              @beforeAction="saveInfo"
              @btnCallback="saveCallback" />
          </q-btn-group>
        </template>
        <template slot="card-detail">
          <div class="col-xs-6 col-sm-6 col-md-2 col-lg-2">
            <c-datepicker
              :editable="editable"
              :disabled="true"
              default="today"
              type="datetime"
              name="traninigEduDt"
              label="훈련·교육 일시"
              v-model="traningEdu.traninigEduDt" />
          </div>
          <div class="col-xs-6 col-sm-6 col-md-2 col-lg-2">
            <c-text
              :editable="editable"
              :disabled="true"
              name="locationName"
              label="장소"
              v-model="traningEdu.locationName" />
          </div>
        </template>
      </c-card>
      <div class="row">
        <div class="col-xs-12 col-sm-12 col-md-4 col-lg-4">
          <c-card title="참석결과" class="cardClassDetailForm q-mt-sm">
            <template slot="card-detail">
              <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6">
                <c-text
                  :editable="editable"
                  :disabled="disabled"
                  name="educationInstructorName"
                  label="교육강사"
                  v-model="traningEdu.educationInstructorName" />
              </div>
              <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6">
                <c-text
                  :editable="editable"
                  :disabled="true"
                  type="number"
                  name="totalNumber"
                  label="총 참석대상(명)"
                  v-model="traningEdu.totalNumber" />
              </div>
              <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6">
                <c-text
                  :editable="editable"
                  :disabled="disabled"
                  type="number"
                  name="attendNumber"
                  label="참석(명)"
                  v-model="traningEdu.attendNumber"
                  @dataChange="personDataChange" />
              </div>
              <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6">
                <c-text
                  :editable="editable"
                  :disabled="disabled"
                  type="number"
                  name="nonAttendNumber"
                  label="미참석(명)"
                  v-model="traningEdu.nonAttendNumber"
                  @dataChange="personDataChange" />
              </div>
            </template>
          </c-card>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-8 col-lg-8">
          <c-card title="자위소방대 참석결과" class="cardClassDetailForm q-mt-sm">
            <template slot="card-detail">
              <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3">
                <c-field
                  :editable="editable"
                  :disabled="disabled"
                  name="selfLeaderId"
                  label="대장"
                  v-model="traningEdu.selfLeaderId"
                  @dataChange="dataChange" />
              </div>
              <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3">
                <c-text
                  :editable="editable"
                  :disabled="true"
                  name="selfLeaderPhoneNumber"
                  label="대장 연락처"
                  v-model="traningEdu.selfLeaderPhoneNumber" />
              </div>
              <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3">
                <c-text
                  :editable="editable"
                  :disabled="true"
                  type="number"
                  name="selfTotalNumber"
                  label="총 참석대상(명)"
                  v-model="traningEdu.selfTotalNumber" />
              </div>
              <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3">
                <c-text
                  :editable="editable"
                  :disabled="disabled"
                  type="number"
                  name="selfUnitLeaderNumber"
                  label="부대장(명)"
                  v-model="traningEdu.selfUnitLeaderNumber"
                  @dataChange="selfDataChange" />
              </div>
              <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3">
                <c-text
                  :editable="editable"
                  :disabled="disabled"
                  type="number"
                  name="selfNotiContactNumber"
                  label="통보연락(명)"
                  v-model="traningEdu.selfNotiContactNumber"
                  @dataChange="selfDataChange" />
              </div>
              <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3">
                <c-text
                  :editable="editable"
                  :disabled="disabled"
                  type="number"
                  name="selfInitFireExtingNumber"
                  label="초기소화(명)"
                  v-model="traningEdu.selfInitFireExtingNumber"
                  @dataChange="selfDataChange" />
              </div>
              <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3">
                <c-text
                  :editable="editable"
                  :disabled="disabled"
                  type="number"
                  name="selfEvacuGuidNumber"
                  label="피난유도(명)"
                  v-model="traningEdu.selfEvacuGuidNumber"
                  @dataChange="selfDataChange" />
              </div>
              <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3">
                <c-text
                  :editable="editable"
                  :disabled="disabled"
                  type="number"
                  name="selfEmergencyContactNumber"
                  label="비상연락(명)"
                  v-model="traningEdu.selfEmergencyContactNumber"
                  @dataChange="selfDataChange" />
              </div>
            </template>
          </c-card>
        </div>
      </div>
      <c-card title="결과" class="cardClassDetailForm">
        <template slot="card-detail">
          <div class="col-xs-12 col-sm-12 col-md-4 col-lg-4">
            <c-textarea
              :editable="editable"
              :disabled="disabled"
              name="mainContent"
              label="주요내용"
              v-model="traningEdu.mainContent" />
          </div>
          <div class="col-xs-12 col-sm-12 col-md-4 col-lg-4">
            <c-textarea
              :editable="editable"
              :disabled="disabled"
              name="mainAchievements"
              label="주요성과"
              v-model="traningEdu.mainAchievements" />
          </div>
          <div class="col-xs-12 col-sm-12 col-md-4 col-lg-4">
            <c-textarea
              :editable="editable"
              :disabled="disabled"
              name="problem"
              label="문제점"
              v-model="traningEdu.problem" />
          </div>
          <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6">
            <c-textarea
              :editable="editable"
              :disabled="disabled"
              name="supplementaryMatters"
              label="보완사항"
              v-model="traningEdu.supplementaryMatters" />
          </div>
          <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6">
            <c-textarea
              :editable="editable"
              :disabled="disabled"
              name="actionTaken"
              label="조치사항"
              v-model="traningEdu.actionTaken" />
          </div>
          <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6">
            <c-upload
              :attachInfo="attachTrainingPictureInfo"
              :editable="editable&&!disabled"
              label="소방훈련 관련사진" />
          </div>
          <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6">
            <c-upload
              :attachInfo="attachEduPictureInfo"
              :editable="editable&&!disabled"
              label="소방교육 관련사진" />
          </div>
        </template>
      </c-card>
    </q-form>
  </div>
</template>
<script>
import transactionConfig from '@/js/transactionConfig';
export default {
  name: 'fireFightingTraningEduResultInfo',
  props: {
    popupParam: {
      type: Object,
      default: () => ({
        sopFireFightingTraningEduId: '',
      }),
    },
    traningEdu: {
      type: Object,
      default: () => ({
        sopFireFightingTraningEduId: '',  // 소방 훈련·교육 계획/결과 일련번호
        plantCd: '',  // 사업장코드
        traninigEduDt: '',  // 훈련·교육 일시
        sopFireFightingTraningEduStepCd: '',  // 소방 훈련·교육 계획/결과 진행상태
        traninigEduName: '',  // 소방 훈련·교육 계획/결과명
        locationName: '',  // 장소
        sopFireFightingTraningEduTargetCd: '',  // 소방 훈련·교육 대상
        sopFireFightingTraningEduPracticeCd: '',  // 소방 훈련·교육 실습
        sopFireFightingTraningEduTheoryCd: '',  // 소방 훈련·교육 이론
        deptCd: '',  // 주관부서
        fireSafetyManagerId: '',  // 소방안전관리자
        fireSafetyManagerQualitClassCd: '',  // 소방안전관리자 자격구분
        fireSafetyManagerNote: '',  // 소방안전관리자 비고
        sopFireFightingTraningEduFormCd: '',  // 소방 훈련 형태
        participatingInstitutions: '',  // 참여기관
        sopFireFightingTraningScenarioId: '',  // 소방 훈련 시나리오 일련번호
        traningPlan: '',  // 훈련계획
        eduPlan: '',  // 교육계획
        evaluationPlanDt: '',  // 평가계획일시
        evaluationUserId: '',  // 평가자
        evaluationMethod: '',  // 평가방법
        evaluationDt: '',  // 평가일시
        educationInstructorName: '',  // 교육강사
        totalNumber: '',  // 총원
        attendNumber: '',  // 참여인원
        nonAttendNumber: '',  // 미참석
        selfTotalNumber: '',  // 자위소방대 총원
        selfLeaderId: '',  // 자위소방대 대장명
        selfLeaderPhoneNumber: '',  // 자위소방대 대장 연락처
        selfUnitLeaderNumber: '',  // 자위소방대 부대장 인원
        selfNotiContactNumber: '',  // 자위소방대 통보연락 인원
        selfInitFireExtingNumber: '',  // 자위소방대 초기소화 인원
        selfEvacuGuidNumber: '',  // 자위소방대 피난유도 인원
        selfEmergencyContactNumber: '',  // 자위소방대 비상연락 인원
        mainContent: '',  // 주요내용
        mainAchievements: '',  // 주요성과
        problem: '',  // 문제점
        supplementaryMatters: '',  // 보완사항
        actionTaken: '',  // 조치사항
        regUserId: '',  // 등록자
        chgUserId: '',  // 수정자
        users: [],
        scenaios: [],
      }),
    },
    attachTrainingPictureInfo: {
      type: Object,
      default: () => ({
        isSubmit: '',
        taskClassCd: 'FIRE_FIGHTING_TRAINING_PICTURE',
        taskKey: '',
      }),
    },
    attachEduPictureInfo: {
      type: Object,
      default: () => ({
        isSubmit: '',
        taskClassCd: 'FIRE_FIGHTING_EDU_PICTURE',
        taskKey: '',
      }),
    },
    isOld: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    contentHeight: null,
  },
  data() {
    return {
      editable: true,
      isSave: false,
      isComplete: false,
      saveUrl: '',
      completeUrl: '',
      deleteUrl: '',
      mappingType: 'PUT',
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  watch: {
  },
  computed: {
  },
  methods: {
    init() {
      // role setting
      this.editable = this.$route.meta.editable;
      // url setting
      this.saveUrl = transactionConfig.fft.fireFightingTraningEdu.update.url
      this.deleteUrl = transactionConfig.fft.fireFightingTraningEdu.delete.url
      this.completeUrl = transactionConfig.fft.fireFightingTraningEdu.actioncomplete.url
      // code setting
      // list setting
    },
    dataChange(userId, user) {
      if (userId && user) {
        this.$set(this.traningEdu, 'selfLeaderPhoneNumber', user.mobileNo)
      } else {
        this.$set(this.traningEdu, 'selfLeaderPhoneNumber', '')
      }
    },
    personDataChange() {
      this.$set(this.traningEdu, 'totalNumber', this.$comm.isNumberEmptyToZero(this.traningEdu.attendNumber)
        + this.$comm.isNumberEmptyToZero(this.traningEdu.nonAttendNumber))
    },
    selfDataChange() {
      this.$set(this.traningEdu, 'selfTotalNumber', this.$comm.isNumberEmptyToZero(this.traningEdu.selfUnitLeaderNumber)
        + this.$comm.isNumberEmptyToZero(this.traningEdu.selfNotiContactNumber)
        + this.$comm.isNumberEmptyToZero(this.traningEdu.selfInitFireExtingNumber)
        + this.$comm.isNumberEmptyToZero(this.traningEdu.selfEvacuGuidNumber)
        + this.$comm.isNumberEmptyToZero(this.traningEdu.selfEmergencyContactNumber))
    },
    saveInfo() {
      this.$refs['editForm'].validate().then(_result => {
        if (_result) {
          window.getApp.$emit('CONFIRM', {
            title: 'LBLCONFIRM',
            message: 'MSGSAVE', // 저장하시겠습니까?
            // TODO : 필요시 추가하세요.
            type: 'info', // success / info / warning / error
            // 확인 callback 함수
            confirmCallback: () => {
              this.traningEdu.chgUserId = this.$store.getters.user.userId;

              this.isSave = !this.isSave;
            },
            // 취소 callback 함수
            cancelCallback: () => {
            },
          });
        } else {
          window.getApp.$emit('APP_VALID_ERROR');
        }
      });
    },
    saveCallback(result) {
      window.getApp.$emit('APP_REQUEST_SUCCESS');
      this.popupParam.sopFireFightingTraningEduId = result.data
      this.$emit('getDetail');
    },
    completeInfo() {
      this.$refs['editForm'].validate().then(_result => {
        if (_result) {
          window.getApp.$emit('CONFIRM', {
            title: 'LBLCONFIRM',
            message: '평가실시완료하시겠습니까?', // 평가실시완료하시겠습니까?
            // TODO : 필요시 추가하세요.
            type: 'info', // success / info / warning / error
            // 확인 callback 함수
            confirmCallback: () => {
              this.traningEdu.chgUserId = this.$store.getters.user.userId;

              this.isComplete = !this.isComplete;
            },
            // 취소 callback 함수
            cancelCallback: () => {
            },
          });
        } else {
          window.getApp.$emit('APP_VALID_ERROR');
        }
      });
    },
    completeCallback() {
      window.getApp.$emit('APP_REQUEST_SUCCESS');
      this.$emit('getDetail');
    },
  }
};
</script>